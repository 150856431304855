import axios from "axios";
import { useEffect, useState } from "react";

interface SettingItem {
  upi_id: string;
  account_number: string;
  bank_name: string;
  ifsc_code: string;
  aff_amount: string;
  account_holder_name: string;
  qr_img: string; // This will store the image URL or path from the server
}

const Settings = () => {
  const [settings, setSettings] = useState<SettingItem>({
    upi_id: "",
    account_number: "",
    bank_name: "",
    ifsc_code: "",
    aff_amount: "",
    account_holder_name: "",
    qr_img: "",
  });
  const [qrFile, setQrFile] = useState<File | null>(null); // To store the uploaded file

  // Fetch settings data on load
  useEffect(() => {
    axios
      .get("https://api.ekjeet.com/getPaymentSetting")
      .then((response) => {
        console.log(response);
        setSettings(response.data[0]);
      })
      .catch((error) => {
        console.error("Error fetching settings:", error);
      });
  }, []);

  // Handle text input changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  // Handle file input change
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setQrFile(e.target.files[0]); // Set the file to state
    }
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Create FormData to send both text data and file
    const formData = new FormData();
    formData.append("upi_id", settings?.upi_id);
    formData.append("account_number", settings?.account_number);
    formData.append("bank_name", settings?.bank_name);
    formData.append("ifsc_code", settings?.ifsc_code);
    formData.append("account_holder_name", settings?.account_holder_name);
    formData.append("aff_amount", settings?.aff_amount);
    if (qrFile) formData.append("qr_img", qrFile); // Only append if file exists

    try {
      const response = await axios.post(
        "https://api.ekjeet.com/updatePaymentSetting",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      alert("Settings updated successfully!");
      setSettings((prevSettings) => ({
        ...prevSettings,
        qr_img: response.data.qr_img, // Update with new image path from the server response
      }));
    } catch (error) {
      console.error("Error updating settings:", error);
      alert("Failed to update settings?.");
    }
  };

  return (
    <div className="ml-0 lg:ml-[256px] p-4">
      <h2 className="text-2xl font-semibold mb-4 text-white">
        Payment Settings
      </h2>
      <form onSubmit={handleSubmit} className="space-y-4 mb-10">
        <div>
          <label className="text-white">UPI ID</label>
          <input
            type="text"
            name="upi_id"
            value={settings?.upi_id}
            onChange={handleChange}
            className="border rounded p-2 w-full"
          />
        </div>
        <div>
          <label className="text-white">Account Number</label>
          <input
            type="text"
            name="account_number"
            value={settings?.account_number}
            onChange={handleChange}
            className="border rounded p-2 w-full"
          />
        </div>
        <div>
          <label className="text-white">Bank Name</label>
          <input
            type="text"
            name="bank_name"
            value={settings?.bank_name}
            onChange={handleChange}
            className="border rounded p-2 w-full"
          />
        </div>
        <div>
          <label className="text-white">IFSC Code</label>
          <input
            type="text"
            name="ifsc_code"
            value={settings?.ifsc_code}
            onChange={handleChange}
            className="border rounded p-2 w-full"
          />
        </div>
        <div>
          <label className="text-white">Account Holder Name</label>
          <input
            type="text"
            name="account_holder_name"
            value={settings?.account_holder_name}
            onChange={handleChange}
            className="border rounded p-2 w-full"
          />
        </div>
        <div>
          <label className="text-white">QR Image</label>
          <input
            type="file"
            name="qr_img"
            onChange={handleFileChange}
            className="border rounded p-2 w-full"
          />
        </div>
        {settings?.qr_img && (
          <div>
            <img
              src={`https://api.ekjeet.com/uploads/${settings?.qr_img}`}
              alt="QR Code"
              className="mt-2 w-32 h-32"
            />
          </div>
        )}
        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded"
        >
          Save Settings
        </button>
      </form>
      <hr className="p-5" />
      <h2 className="text-2xl font-semibold mb-4 text-white">
        Affiliate Commission Settings
      </h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="text-white">Affiliate Commission</label>
          <input
            type="text"
            name="aff_amount"
            value={settings?.aff_amount}
            onChange={handleChange}
            className="border rounded p-2 w-full"
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 rounded"
        >
          Save
        </button>
      </form>
    </div>
  );
};

export default Settings;
