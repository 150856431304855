import React, { useState, useEffect } from 'react';
import axios from 'axios';

type ReferralUser = {
  id: string;
  name: string;
  uid: string;
  referral_link: string;
  referalcode: string;
  referrer_name: string;
};

const Referral: React.FC = () => {
  const [referralUsers, setReferralUsers] = useState<ReferralUser[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchReferralUsers();
  }, []);

  const fetchReferralUsers = async () => {
    try {
      const response = await axios.get('https://api.ekjeet.com/getUserDataAffi'); // Adjust the endpoint accordingly
      const data = response.data;

      if (Array.isArray(data)) {
        setReferralUsers(data);
      } else {
        console.error('Unexpected response structure:', data);
        setReferralUsers([]);
      }
    } catch (error) {
      console.error('Failed to fetch referral users:', error);
      setReferralUsers([]);
    }
  };

  const filteredReferralUsers = referralUsers.filter(user => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    
    // Ensure all fields are converted to strings before calling toLowerCase()
    return (
      String(user.name).toLowerCase().includes(lowerCaseSearchTerm) ||
      String(user.id).toLowerCase().includes(lowerCaseSearchTerm) ||
      String(user.referral_link).toLowerCase().includes(lowerCaseSearchTerm) ||
      String(user.referalcode).toLowerCase().includes(lowerCaseSearchTerm) ||
      String(user.referrer_name).toLowerCase().includes(lowerCaseSearchTerm)
    );
  });
  

  return (
    <div className='ml-0 lg:ml-[256px] p-8 '>
      <h1 className="text-3xl font-bold mb-8 text-white">Referral Users</h1>
      <h1 className="text-2xl text-white font-semibold mb-4">{`Total Users: ${referralUsers.length}`}</h1>

      <div className="mb-4 flex justify-end mb-5">
        <label htmlFor="search" className="sr-only">Search</label>
        <div className="relative w-1/2">
          <input
            type="text"
            id="search"
            className="bg-[#37384A] w-full text-white pl-10 pr-4 py-2 rounded-lg border border-gray-200 shadow-sm focus:outline-none focus:ring-2  focus:border-transparent"
            placeholder="Search by name, UID, or referral details"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg className="h-5 w-5 text-gray-500" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto relative shadow-md sm:rounded-lg bg-white">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-white uppercase bg-[#646ECD]">
            <tr>
              <th scope="col" className="py-3 px-6">S.No</th>
              <th scope="col" className="py-3 px-6">Name</th>
              <th scope="col" className="py-3 px-6">UID</th>
              <th scope="col" className="py-3 px-6">Referral Link</th>
              <th scope="col" className="py-3 px-6">Referral Code</th>
              <th scope="col" className="py-3 px-6">Friend</th>
            </tr>
          </thead>
          <tbody>
            {filteredReferralUsers.length > 0 ? (
              filteredReferralUsers.map((user, index) => (
                <tr key={user.id} className="group bg-[#37384A] border-b border-gray-200 hover:bg-gray-50">
                  <td className="text-white group-hover:text-gray-600 py-4 px-6">{index + 1}</td>
                  <td className="text-white group-hover:text-gray-600 py-4 px-6">{user.name}</td>
                  <td className="text-white group-hover:text-gray-600 py-4 px-6">{user.id}</td>
                  <td className="text-white group-hover:text-gray-600 py-4 px-6">{user.referral_link}</td>
                  <td className="text-white group-hover:text-gray-600 py-4 px-6">{user.referalcode}</td>
                  <td className="text-white group-hover:text-gray-600 py-4 px-6">{user.referrer_name}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="py-4 px-6 text-center text-white">No matching users found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Referral;
