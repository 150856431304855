import axios from 'axios';
import { useEffect, useState } from 'react';

interface DepositItem {
  uid: string;
  transaction_id: number;
  transaction_date: string;
  name: string;
  amount: number;
  status: string;
  utr_no: string;
}

const ManualDeposit = () => {
  const [isManualPayment, setIsManualPayment] = useState(true); // To toggle between manual and online payments
  const [depositData, setDepositData] = useState<DepositItem[]>([]);

  useEffect(() => {
    fetchDepositData();
  }, [isManualPayment]); // Re-fetch data when payment type changes

  // Fetch data from the API based on transaction type
  const fetchDepositData = async () => {
    try {
      const transactionType = isManualPayment ? 'manual' : 'online'; // Set transaction type based on state
      const response = await axios.get('https://api.ekjeet.com/getManualDepositData', {
        params: { transactionType },
      });
      const data = response.data;

      if (Array.isArray(data)) {
        const reversedData = data.reverse(); // Optionally reverse data for most recent first
        setDepositData(reversedData);
      } else {
        console.error('Unexpected response structure:', data);
        setDepositData([]);
      }
    } catch (error) {
      console.error('Failed to fetch deposit data:', error);
      setDepositData([]);
    }
  };

  const handleManualPaymentToggle = () => {
    setIsManualPayment(true);
  };

  const handleOnlinePaymentToggle = () => {
    setIsManualPayment(false);
  };

  const handleAcceptPayment = async (transactionId: number, utrNo: string, uid: string, amount: number, coin: string) => {
    // Call the API to approve the payment
    await approveOrRejectManualDeposit(utrNo, uid, coin, amount, true);
    console.log(`Accepted payment for transaction ID: ${transactionId}`);
  };

  const handleRejectPayment = async (transactionId: number, utrNo: string, uid: string, amount: number, coin: string) => {
    // Call the API to reject the payment
    await approveOrRejectManualDeposit(utrNo, uid, coin, amount, false);
    console.log(`Rejected payment for transaction ID: ${transactionId}`);
  };

  const approveOrRejectManualDeposit = async (utr_no: string, uid: string, coin: string, amount: number, isApproved: boolean) => {
    try {
      await axios.post('https://api.ekjeet.com/approveRejectManualDeposit', {
        utr_no,
        uid,
        coin,
        amount,
        isApproved,
      });
      // Optionally refresh deposit data after processing
      fetchDepositData();
    } catch (error) {
      console.error('Error processing manual deposit:', error);
    }
  };

  return (
    <div className="ml-0 lg:ml-[256px]">
      <div className="flex gap-4 mt-4">
        <button
          className={`px-4 py-2 rounded ${isManualPayment ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
          onClick={handleManualPaymentToggle}
        >
          Manual Payment
        </button>
        <button
          className={`px-4 py-2 rounded ${!isManualPayment ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
          onClick={handleOnlinePaymentToggle}
        >
          Online Payment
        </button>
      </div>

      {/* Conditional Table */}
      <div className="mt-6">
        {isManualPayment ? (
          <div>
            <h2 className="text-xl font-semibold mb-4 text-white">Manual Payment Details</h2>
            <table className="min-w-full bg-white bg-opacity-5 text-white">
              <thead>
                <tr>
                  <th className="px-4 py-2 border">SNo.</th>
                  <th className="px-4 py-2 border">User Name</th>
                  <th className="px-4 py-2 border">Amount</th>
                  <th className="px-4 py-2 border">UTR No</th>
                  <th className="px-4 py-2 border">Status</th>
                  <th className="px-4 py-2 border">Date</th>
                  <th className="px-4 py-2 border">Actions</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {depositData.map((deposit, index) => (
                  <tr key={deposit.transaction_id}>
                    <td className="px-4 py-2 border">{index + 1}</td>
                    <td className="px-4 py-2 border">{deposit.name}</td>
                    <td className="px-4 py-2 border">₹{deposit.amount.toFixed(1)}</td>
                    <td className="px-4 py-2 border">{deposit.utr_no}</td>
                    <td className="px-4 py-2 border">{deposit.status}</td>
                    <td className="px-4 py-2 border">{new Date(deposit.transaction_date).toLocaleString()}</td>
                    <td className="px-4 py-2 border">
                      {deposit.status === 'pending' ? (
                        <>
                          <button
                            className="px-3 py-1 mr-2 bg-green-500 text-white rounded"
                            onClick={() => handleAcceptPayment(deposit.transaction_id, deposit.utr_no, deposit.uid, deposit.amount, 'inr')}
                          >
                            Accept
                          </button>
                          <button
                            className="px-3 py-1 bg-red-500 text-white rounded"
                            onClick={() => handleRejectPayment(deposit.transaction_id, deposit.utr_no, deposit.uid, deposit.amount, 'inr')}
                          >
                            Reject
                          </button>
                        </>
                      ) : deposit.status === 'accepted' ? (
                        <button className="px-3 py-1 mr-2 bg-green-500 text-white rounded">Accepted</button>
                      ) : (
                        <button className="px-3 py-1 mr-2 bg-red-500 text-white rounded">Rejected</button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>
            <h2 className="text-xl font-semibold mb-4 text-white">Online Payment Details</h2>
            <table className="min-w-full bg-white bg-opacity-5 text-white">
              <thead>
                <tr>
                  <th className="px-4 py-2 border">SNo.</th>
                  <th className="px-4 py-2 border">User Name</th>
                  <th className="px-4 py-2 border">Amount</th>
                  <th className="px-4 py-2 border">UTR No</th>
                  <th className="px-4 py-2 border">Status</th>
                  <th className="px-4 py-2 border">Date</th>
                  <th className="px-4 py-2 border">Actions</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {depositData.map((deposit, index) => (
                  <tr key={deposit.transaction_id}>
                    <td className="px-4 py-2 border">{index + 1}</td>
                    <td className="px-4 py-2 border">{deposit.name}</td>
                    <td className="px-4 py-2 border">₹{deposit.amount.toFixed(1)}</td>
                    <td className="px-4 py-2 border">{deposit.utr_no}</td>
                    <td className="px-4 py-2 border">{deposit.status}</td>
                    <td className="px-4 py-2 border">{new Date(deposit.transaction_date).toLocaleString()}</td>
                    <td className="px-4 py-2 border">
                      {deposit.status === 'pending' ? (
                        <>
                          
                          <button
                            className="px-3 py-1 bg-yellow-500 text-white rounded"
                            // onClick={() => handleRejectPayment(deposit.transaction_id, deposit.utr_no, deposit.uid, deposit.amount, 'inr')}
                          >
                            Pending
                          </button>
                        </>
                      ) : deposit.status === 'accepted' ? (
                        <button className="px-3 py-1 mr-2 bg-green-500 text-white rounded">Success</button>
                      ) : (
                        <button className="px-3 py-1 mr-2 bg-red-500 text-white rounded">Rejected</button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ManualDeposit;
